import { createSlice } from "@reduxjs/toolkit";

const conditionalUiSlice = createSlice({
  name: "conditionalUi",
  initialState: {
    activeEventTab: 1,
    displayScheduler: true,
    openSuccess: {
      open: false,
      message: "",
    },
    firstTimeWalkthrough: false,
  },
  reducers: {
    setActiveEventTab(state, action) {
      state.activeEventTab = action.payload;
    },
    setOpenSuccess(state, action) {
      const { open, message } = action.payload;
      state.openSuccess.open = open;
      state.openSuccess.message = message;
    },
    setDisplayScheduler(state, action) {
      const newValue = !state.displayScheduler;
      state.displayScheduler = action.payload !== undefined ? action.payload : newValue;
    },
    setFirstTimeWalkthrough(state, action) {
      state.firstTimeWalkthrough = action.payload;
    },
  },
});

export const { setActiveEventTab, setDisplayScheduler, setOpenSuccess, setFirstTimeWalkthrough } = conditionalUiSlice.actions;

export default conditionalUiSlice.reducer;