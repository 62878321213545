import { createSlice } from "@reduxjs/toolkit";

const webinarEventSlice = createSlice({
  name: "event",
  initialState: {
    webinarEvent: {
      id: "",
      topic: "",
      description: "",
      date: "",
      category: "",
      subcategory: "",
      other: "",
      meetingLength: 60,
      meetingEndTime: 100,
      addToSearch: false,
      date: "",
      meetingStartTime: 0,
      timezoneOffset: "",
      timezoneAbbreviation: "",
      icalObject: "",

    },
    allEvents: [],
    filteredWebinars: [],
    searchQuery: "",
    timeAndDate: {},
    registrationGraphics: {},
    confirmationInfo: {
      thankYouHeader: "Thank you for registering!",
      optionalMessage: "",

    },
    confirmationGraphics: {
      fontColor: "#000",
      backgroundColor: "#FFF",
      backgroundImage: "",
    },
    archivedWebinars: [],
  },
  reducers: {
    setWebinarEvent: (state, action) => {
      state.webinarEvent = action.payload;
    },
    updateWebinarEventField: (state, action) => {
      const { field, value } = action.payload;
      state.webinarEvent[field] = value;
    },
    updateConfirmationInfo: (state, action) => {
      const { field, value } = action.payload;
      state.confirmationInfo[field] = value;
    },
    setAllEvents: (state, action) => {
      state.allEvents = action.payload;
    },
    setArchivedWebinars: (state, action) => {
      state.archivedWebinars = action.payload;
    },
    setFilteredWebinars: (state, action) => {
      state.filteredWebinars = action.payload;
    },
    setSearchQuery: (state, action) => {
      state.searchQuery = action.payload;
    },
    setTimeAndDate: (state, action) => {
      state.timeAndDate = action.payload;
    },
    setStyles: (state, action) => {
      state.registrationGraphics = action.payload;
    },
    setConfirmationInfo: (state, action) => {
      state.confirmationInfo = action.payload;
    },
    setConfirmationGraphics: (state, action) => {
      state.confirmationGraphics = action.payload;
    },
    deleteWebinar: (state, action) => {
      const webinarIdToDelete = action.payload;
      state.allEvents = state.allEvents.filter(webinar => webinar.id !== webinarIdToDelete);
    },
    addWebinarToArchive: (state, action) => {
      const {id, webinar} = action.payload;
      const archivedWebinarIndex = state.allEvents.findIndex((webinar) => webinar.id === id);

      if (archivedWebinarIndex !== -1) {
        state.allEvents = state.allEvents.filter(webinar => webinar.id !== id);
        const archived = state.archivedWebinars;
        archived.push(webinar);
        state.archivedWebinars = archived;
      }
    },
  }
});

export const {
  setWebinarEvent,
  updateWebinarEventField,
  setAllEvents,
  setArchivedWebinars,
  setSearchQuery,
  setFilteredWebinars,
  setTimeAndDate,
  setStyles,
  setConfirmationInfo,
  setConfirmationGraphics,
  deleteWebinar,
  addWebinarToArchive,
  updateConfirmationInfo
} = webinarEventSlice.actions;
export default webinarEventSlice.reducer;