import React, { useEffect, useState, lazy, Suspense } from "react";
import { Route, Routes, useLocation, redirect } from "react-router-dom";
import { Box } from "@mui/material";
import Categories from "./Categories";
import { Divider } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material";
import webinarApi from "./utils/zoomCorderApi";
import InfiniteScroll from "react-infinite-scroll-component";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useSelector, useDispatch } from "react-redux";
import "./App.css";
import "./satellite.css";
import { setWebinarsData } from "./redux/slices/webinarsSlice";
import InlinePlayer2 from "./inlinePlayer2";
// import { handleTokenRefresh } from "./tokenManager";

const Main = lazy(() => import("./Main"));
// const InlinePlayer2 = lazy(() => import("./inlinePlayer2"));
const Menu = lazy(() => import("./Menu"));
const WebinarProfile2 = lazy(() => import("./WebinarProfile2"));
const Footer = lazy(() => import("./Footer"));

const clientID = process.env.REACT_APP_CLIENT_ID;
const clientSecret = process.env.REACT_APP_CLIENT_SECRET;

// Import components algolia

// Check and refresh the token every 30 minutes (adjust the interval as needed)
// setInterval(handleTokenRefresh, 30 * 60 * 1000);

var apiServer = "https://us-central1-webinarstvus.cloudfunctions.net/";
var headers = {
  "Content-Type": "text/plain",
};
var x;
var webinarHolder = [];
if (!x) {
  x = {};
}
var webinarListInfoRef = {};
var timeFrameRef;
if (!timeFrameRef) {
  timeFrameRef = "day";
}

const theme = createTheme({
  typography: {
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif;`,
  },
  palette: {
    secondary: {
      main: "#de351f",
    },
    notif: {
      main: "#ff7614",
    },
    grey: {
      main: "#888888",
    },
  },

  button: {
    "text-transform": "none",
  },
});

function App(ReactGA) {
  const [chartData, setChartData] = useState(x);
  const [webinarData, setWebinarData] = useState(webinarHolder);
  const [webinarData2, setWebinarData2] = useState(webinarHolder);
  const [webinarListInfo, setWebinarListInfo] = useState(webinarListInfoRef);

  const dispatch = useDispatch();
  var reactGA;
  if (ReactGA) {
    reactGA = ReactGA.reactGA;
  }
  console.log("reactGA", reactGA);
  if (webinarListInfo) {
    webinarListInfoRef = webinarListInfo;
  }

  const [timeFrame, setTimeFrame] = useState(timeFrameRef);
  const [selectedTimeScale, setSelectedTimeScale] = useState(2);

  var { pathname } = useLocation();
  if (window.location.hostname.toLocaleLowerCase().includes("topwebinars.us")) {
    window.location.replace(
      window.location.href.replace("topwebinars.us", "webinartv.us")
    );
  }

  const root = document.getElementById("rootBody");
  if (window.location.pathname.split("/")[1] === "embed") {
    root.classList.add("rootBody2");
  }
  // useEffect(() => {
  //   window.addEventListener("load", function () {
  //     reactGA.set({ page: window.location.href });

  //     reactGA.pageview(window.location.href);
  //     //console.log("pageview0", window.location.href);
  //   });
  // }, []);

  var chartID = window.location.pathname.split("/")[2];
  // if (timeFrame) {
  //   timeFrameRef = timeFrame;
  // }
  // useEffect(() => {
  //   var url;
  //   url = `getCategories`;
  //   fetch(
  //     apiServer + url,

  //     {
  //       method: "GET",
  //       headers: headers,
  //     }
  //   )
  //     .then((res) => {
  //       return res.json();
  //     })
  //     .then((preResponse) => {
  //       console.log("getcategories", preResponse);
  //       let tempArr = webinarListInfoRef;

  //       tempArr = { ...tempArr, ...preResponse };
  //       webinarListInfoRef = tempArr;
  //       setWebinarListInfo(tempArr);
  //       console.log("categories", webinarList);
  //     });
  // }, [webinarList]);

  // if (window.location.pathname !== "/chart") {
  //   useEffect(() => {
  //     var temptimeFrameOffset = Date.now();

  //     var url;
  //     let urlBase = "getShowCategories";
  //     let pastTempString = "past=" + timeFrame + "&";

  //     if (timeFrame === "month") {
  //       urlBase = "getShows"; //getShowCategories
  //     }

  //     url = `${urlBase}?size=200&${(urlBase === "getShows" && pastTempString && pastTempString) || ""
  //       }sort=trending`;

  // const inStorage = localStorage.getItem("webinarsList");
  // let parsedList;
  // let timeStamp = 0;
  // if (inStorage?.length) {
  //   parsedList = JSON.parse(inStorage);
  //   timeStamp = parsedList?.timestamp;
  // }
  // const webinarsObject = inStorage;
  // const currentTime = Date.now();
  // const timeSinceLastFetch = currentTime - timeStamp;
  // debugger;
  // if (!inStorage?.length || timeSinceLastFetch >= 1000 /*10800000 */) {
  //   fetch(
  //     "https://storage.googleapis.com/webinartv-200-webinars/webinars-200.json"
  //   )
  //     .then((res) => {
  //       debugger;
  //       return res.json();
  //     })
  //     .then((preResponse) => {
  //       debugger;
  //       console.log("preResponse", preResponse)
  //       var tempChartData = { ...chartData };
  //       // console.log(tempChartData, chartID, timeFrame);
  //       tempChartData[chartID] = {};
  //       tempChartData[chartID][timeFrame] = {
  //         ...preResponse.data,
  //       };
  //       if (
  //         !chartData ||
  //         !chartData[chartID] ||
  //         !chartData[chartID][timeFrame]
  //       ) {
  //         // console.log("rerender", chartData);
  //         chartDataSetter(tempChartData);
  //         dispatch(setWebinarsData(preResponse.data));
  //       } else {
  //         chartDataSetter(tempChartData);
  //         dispatch(setWebinarsData(preResponse.data));
  //       }
  //       // debugger;
  //       const prepData = preResponse.data;
  //       const tStamp = Date.now();
  //       const newData = { ...prepData, timestamp: tStamp };
  //       const cloned = JSON.parse(JSON.stringify(newData))
  //       localStorage.setItem(
  //         "webinarsList",
  //         JSON.stringify(cloned)
  //       );
  //       console.log("tempChartdata1", tempChartData, preResponse.docs);

  //       var cdHolder =
  //         tempChartData[Object.keys(tempChartData)[0]][timeFrame];

  //       if (
  //         cdHolder[Object.keys(cdHolder)[Object.keys(cdHolder).length - 1]]
  //       ) {
  //         // startAt =
  //         //   cdHolder[Object.keys(cdHolder)[Object.keys(cdHolder).length - 1]]
  //         //     .score;
  //         // currentEnd = 0;
  //         // timeOffset =
  //         //   cdHolder[Object.keys(cdHolder)[Object.keys(cdHolder).length - 1]]
  //         //     .meetingStartTime;
  //       } else {
  //         console.log("empty");
  //       }
  //       console.log("loadingpagefalse");
  //       // setLoadingPage(false);
  //     })
  //     .catch((error) => {
  //       console.log(`ERROR WEBINARS FETCH: ${error}`);
  //     })
  //     ;

  // } else {
  //   var tempChartData = { ...chartData };
  //   tempChartData[chartID] = {};
  //   tempChartData[chartID][timeFrame] = {
  //     ...parsedList.data,
  //   };
  // }
  // }, []);

  //   x = chartData;
  //   webinarHolder = webinarData;
  //   function setTime(time, selectedID) {
  //     setTimeFrame(time);
  //     setSelectedTimeScale(selectedID);
  //   }
  // }
  function chartDataSetter(param) {
    console.log("paramo", param, chartData, { ...param, ...chartData });
    if (chartData !== { ...param, ...chartData }) {
      setChartData({ ...param, ...chartData });
    }
  }
  var webinarList = [];

  if (window.location.pathname.split("/")[2]) {
    if (
      window.location.pathname.split("/")[2].toLocaleLowerCase() === "business"
    ) {
      webinarList = [
        "Entrepreneurship",
        "Finance",
        "Human Resources",
        "Jobs/Careers",
        "Marketing",
        "MBA",
        "Product Management",
        "Sales",
        "Startups",
      ];
    } else if (
      window.location.pathname.split("/")[2].toLocaleLowerCase() ===
      "education/family"
    ) {
      webinarList = ["School Activities"];
    } else if (
      window.location.pathname.split("/")[2].toLocaleLowerCase() ===
      "entertainment"
    ) {
      webinarList = [
        "Cooking",
        "Fashion",
        "Film",
        "Music",
        "Travel",
        "Visual Arts",
      ];
    } else if (
      window.location.pathname.split("/")[2].toLocaleLowerCase() === "fitness"
    ) {
      webinarList = ["Yoga", "Zumba"];
    } else if (
      window.location.pathname.split("/")[2].toLocaleLowerCase() === "Health"
    ) {
      webinarList = ["Recovery"];
    }
  } else {
    webinarList = [
      "Business",
      "Community/Charity",
      "Education/Family",
      "Entertainment",
      "Fitness",
      "Health",
      "Motivation/Spirituality",
      "Politics/Govt",
      "Science/Tech",
      "Variety",
    ];
  }
  useEffect(() => {
    document.body.scrollTo(0, 0);
  }, [pathname]);

  window.mobileCheck = function () {
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  };
  let isMobilePortrait = window.mobileCheck();

  useEffect(() => {
    fetch(
      "https://storage.googleapis.com/webinartv-200-webinars/webinars-200.json"
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((preResponse) => {
        chartDataSetter(preResponse);
        dispatch(setWebinarsData(preResponse));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  return (
    (window.location.pathname.split("/")[1] !== "embed" && (
      <GoogleOAuthProvider clientId={clientID}>
        <div
          className="App"
          style={{ marginTop: isMobilePortrait ? "118px" : "65px" }}
        >
          <div style={{ width: 0, height: 0 }}></div>
          <ThemeProvider theme={theme}>
            <div className="width100vw">
              <Routes>
                {
                  // <Route
                  //   exact
                  //   path="/"
                  //   element={
                  //     <div className="webinarTable">
                  //       {!isMobilePortrait && (
                  //         <Suspense fallback={<div>loading...</div>}>
                  //           <Divider />
                  //           <WebinarProfile2
                  //             chartID={`${window.location.pathname.split("/")[2]
                  //               }`}
                  //             timeFrame={timeFrame}
                  //             // setChartData={chartDataSetter}
                  //             // chartData={chartData}
                  //             webinarData={webinarData}
                  //             // setWebinarData={setWebinarData}
                  //             webinarData2={webinarData2}
                  //             setWebinarData2={setWebinarData2}
                  //           />
                  //         </Suspense>
                  //       )}
                  //     </div>
                  //   }
                  // />
                }
                <Route
                  exact
                  path="/chart"
                  element={
                    <div style={{ "margin-left": "80px" }}>
                      <Suspense fallback={<div>loading...</div>}>
                        <Main
                          chartID={`${window.location.pathname.split("/")[2]}`}
                          timeFrame={timeFrame}
                          chartData={chartData}
                          setChartData={chartDataSetter}
                        />
                      </Suspense>
                    </div>
                  }
                />
              </Routes>

              <div className="width100vw">
                <Suspense fallback={<div>Loading...</div>}>
                  <Menu reactGA={reactGA} />
                </Suspense>
              </div>

              <Suspense fallback={<div>Loading...</div>}>
                <Footer />
              </Suspense>
              {/* {(window.location.pathname.split("/")[1] !== "about" &&
              window.location.pathname.split("/")[1] !== "player" &&
              window.location.pathname.split("/")[1] !== "embed" &&
              !window.location.pathname.split("/")[1] && (
                <div className="inline">
                  {(webinarList.length > 5 && (
                    <div className="inline">
                      <ul>
                        {webinarList &&
                          webinarList.map(
                            (row, index) =>
                              index < webinarList.length / 2 && (
                                <li>
                                  <a
                                    href={`/Webinars/${row
                                      .split("/")
                                      .join("%2F")}/`}
                                  >
                                    {row}{" "}
                                    {webinarListInfo &&
                                      webinarListInfo[row] &&
                                      `(${webinarListInfo[row]})`}
                                  </a>
                                </li>
                              )
                          )}
                      </ul>
                      <ul>
                        {webinarList &&
                          webinarList.map(
                            (row, index) =>
                              index > webinarList.length / 2 && (
                                <li>
                                  <a
                                    href={`/Webinars/${row
                                      .split("/")
                                      .join("%2F")}/`}
                                  >
                                    {row}{" "}
                                    {webinarListInfo &&
                                      webinarListInfo[row] &&
                                      `(${webinarListInfo[row]})`}
                                  </a>
                                </li>
                              )
                          )}
                      </ul>
                    </div>
                  )) || (
                    <ul>
                      {webinarList &&
                        webinarList.map(
                          (row, index) =>
                            index < webinarList.length && (
                              <li>
                                <a
                                  href={`/Webinars/${row
                                    .split("/")
                                    .join("%2F")}/`}
                                >
                                  {row}{" "}
                                  {webinarListInfo &&
                                    webinarListInfo[row] &&
                                    `(${webinarListInfo[row]})`}
                                </a>
                              </li>
                            )
                        )}
                    </ul>
                  )}
                </div>
              )) || (
              <div>
                <br />
                <br />
              </div>
            )} */}
              {/*
            {!window.location.pathname.split("/")[3] && (
              <div className="inline">
                <ul style={{ "padding-left": "3px" }}>
                  <li>
                    <a href="/Webinars/Business/">Business</a>
                    <ul>
                      <li>
                        <a href="/Webinars/Entrepreneurship/">
                          Entrepreneurship
                        </a>
                      </li>

                      <li>
                        <a href="/Webinars/Finance/">Finance</a>
                      </li>
                      <li>
                        <a href="/Webinars/Human%20Resources/">
                          Human Resources
                        </a>
                      </li>

                      <li>
                        <a href="/Webinars/Jobs%2FCareers/">Jobs/Careers</a>
                      </li>

                      <li>
                        <a href="/Webinars/Marketing/">Marketing</a>
                      </li>

                      <li>
                        <a href="/Webinars/MBA/">MBA</a>
                      </li>

                      <li>
                        <a href="/Webinars/Product%20Management/">
                          Product Management
                        </a>
                      </li>

                      <li>
                        <a href="/Webinars/Sales/">Sales</a>
                      </li>

                      <li>
                        <a href="/Webinars/Startups/">Startups</a>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  <li>
                    <a href="/Webinars/Community%2FCharity/">
                      Community/Charity
                    </a>
                  </li>
                  <li>
                    <a href="/Webinars/Education%2FFamily/">Education/Family</a>
                    <ul>
                      <li>
                        <a href="/Webinars/School%20Activities/">
                          School Activities
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="/Webinars/Entertainment/">Entertainment</a>
                    <ul>
                      <li>
                        <a href="/Webinars/Cooking/">Cooking</a>
                      </li>
                      <li>
                        <a href="/Webinars/Fashion/">Fashion</a>
                      </li>
                      <li>
                        <a href="/Webinars/Film/">Film</a>
                      </li>
                      <li>
                        <a href="/Webinars/Music/">Music</a>
                      </li>
                      <li>
                        <a href="/Webinars/Travel/">Travel</a>
                      </li>
                      <li>
                        <a href="/Webinars/Visual%20Arts/">Visual Arts</a>
                      </li>
                    </ul>
                  </li>
                </ul>
                <ul>
                  {" "}
                  <li>
                    <a href="/Webinars/Fitness/">Fitness</a>
                    <ul>
                      <li>
                        <a href="/Webinars/Yoga/">Yoga</a>
                      </li>
                      <li>
                        <a href="/Webinars/Zumba/">Zumba</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="/Webinars/Health/">Health</a>
                    <ul>
                      <li>
                        <a href="/Webinars/Recovery/">Recovery</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="/Webinars/Motivation%2FSpirituality/">
                      Motivation/Spirituality
                    </a>
                  </li>{" "}
                  <li>
                    <a href="/Webinars/Politics%2FGovt/">Politics/Govt</a>
                  </li>{" "}
                  <li>
                    <a href="/Webinars/Science%2FTech/">Science/Tech</a>
                  </li>{" "}
                  <li>
                    <a href="/Webinars/Variety/">Variety</a>
                  </li>
                </ul>
              </div>
            )}*/}
              {/* </div> */}

              {/*<BrowserRouter>*/}
              {/* <Route exact path="/">
                <div className="width100vw">
                  <h3>&nbsp;&nbsp;Live webinars we think you’ll like</h3>
                  <Suspense fallback={<div>loading...</div>}>
                    <Carousel
                      chartID={`${window.location.pathname.split("/")[2]}`}
                      timeFrame={timeFrame}
                      chartData={chartData}
                      setChartData={chartDataSetter}
                    />
                  </Suspense> */}
              {/*
                <h3>Trending</h3>
                <Carousel
                  chartID={`${window.location.pathname.split("/")[2]}`}
                  timeFrame={timeFrame}
                  chartData={chartData}
                  setChartData={chartDataSetter}
                />
                <h3>Reccomended Business Webinars</h3>
                <Carousel
                  chartID={`${window.location.pathname.split("/")[2]}`}
                  timeFrame={timeFrame}
                  chartData={chartData}
                  setChartData={chartDataSetter}
                  type={"business"}
              />*/}
              {/* <div className="timeFrameSelector">
                    <div
                      className={
                        (selectedTimeScale === 2 && "timeFrameItemActive") ||
                        "timeFrameItem"
                      }
                      onClick={() => {
                        setTime("day", 2);
                      }}
                    >
                      On Now
                    </div>{" "}
                    <div
                      className={
                        (selectedTimeScale === 4 && "timeFrameItemActive") ||
                        "timeFrameItem"
                      }
                      onClick={() => {
                        setTime("month", 4);
                      }}
                    >
                      Popular
                    </div>
                  </div> */}

              {/*}           <Suspense fallback={<div>loading...</div>}>
                    <Main
                      chartID={`${window.location.pathname.split("/")[2]}`}
                      timeFrame={timeFrame}
                      chartData={chartData}
                      setChartData={chartDataSetter}
                    />
                </div>
              </Suspense>
            </Route>
                */}
              {/*
              <Route exact path="/newHome">
                <Suspense fallback={<div>loading...</div>}>
                  <Home
                    chartID={`${window.location.pathname.split("/")[2]}`}
                    timeFrame={timeFrame}
                    chartData={chartData}
                    setChartData={chartDataSetter}
                  />
                </Suspense>
              </Route> */}
              {/* <Route exact path="/player">
                <div className="width100vw">
                  <h3>Recommended</h3>
                  <Suspense fallback={<div>loading...</div>}>
                    <Carousel
                      chartID={`${window.location.pathname.split("/")[2]}`}
                      timeFrame={timeFrame}
                      chartData={chartData}
                      setChartData={chartDataSetter}
                    />
                    <h3>Trending</h3>
                    <Carousel
                      chartID={`${window.location.pathname.split("/")[2]}`}
                      timeFrame={timeFrame}
                      chartData={chartData}
                      setChartData={chartDataSetter}
                    />
                    <h3>Reccomended Business Webinars</h3>
                    <Carousel
                      chartID={`${window.location.pathname.split("/")[2]}`}
                      timeFrame={timeFrame}
                      chartData={chartData}
                      setChartData={chartDataSetter}
                      type={"business"}
                    />
                  </Suspense>
                  <div className="timeFrameSelector">
                    <div
                      className={
                        (selectedTimeScale === 2 && "timeFrameItemActive") ||
                        "timeFrameItem"
                      }
                      onClick={() => {
                        setTime("day", 2);
                      }}
                    >
                      On Now
                    </div>{" "}
                    <div
                      className={
                        (selectedTimeScale === 4 && "timeFrameItemActive") ||
                        "timeFrameItem"
                      }
                      onClick={() => {
                        setTime("month", 4);
                      }}
                    >
                      Popular
                    </div>
                  </div>
                  <Suspense fallback={<div>loading...</div>}>
                    <Main
                      chartID={`${window.location.pathname.split("/")[2]}`}
                      timeFrame={timeFrame}
                      chartData={chartData}
                      setChartData={chartDataSetter}
                    />
                  </Suspense>
                </div>
              </Route> */}
              {/* <Route exact path="/webinars">
              <div className="width100vw">
                <Suspense fallback={<div>loading...</div>}>
                  <WebinarProfile
                    chartID={`${window.location.pathname.split("/")[2]}`}
                    timeFrame={timeFrame}
                    setChartData={chartDataSetter}
                    chartData={chartData}
                    webinarData={webinarData}
                    setWebinarData={setWebinarData}
                  />
                  <Carousel
                    chartID={`${window.location.pathname.split("/")[2]}`}
                    timeFrame={timeFrame}
                    chartData={chartData}
                    setChartData={chartDataSetter}
                  />
                  <Carousel
                    chartID={`${window.location.pathname.split("/")[2]}`}
                    timeFrame={timeFrame}
                    chartData={chartData}
                    setChartData={chartDataSetter}
                  />
                  <Carousel
                    chartID={`${window.location.pathname.split("/")[2]}`}
                    timeFrame={timeFrame}
                    chartData={chartData}
                    setChartData={chartDataSetter}
                  />
                </Suspense> */}
              {/* <div className="timeFrameSelector">
                  <div
                    className={
                      (selectedTimeScale === 2 && "timeFrameItemActive") ||
                      "timeFrameItem"
                    }
                    onClick={() => {
                      setTime("day", 2);
                    }}
                  >
                    On Now
                  </div>{" "}
                  <div
                    className={
                      (selectedTimeScale === 4 && "timeFrameItemActive") ||
                      "timeFrameItem"
                    }
                    onClick={() => {
                      setTime("month", 4);
                    }}
                  >
                    Popular
                  </div>
                </div>{" "}
                <Suspense fallback={<div>loading...</div>}>
                  <Main
                    chartData={chartData}
                    setChartData={chartDataSetter}
                    chartID={`${window.location.pathname.split("/")[2]}`}
                    timeFrame={timeFrame}
                  />
                </Suspense>
              </div>
            </Route> */}
              {/* <Route path="/webinars/:category/:webinar">
              <Suspense fallback={<div>loading...</div>}>
                <WebinarProfile
                  chartID={`${window.location.pathname.split("/")[2]}`}
                  timeFrame={timeFrame}
                  setChartData={chartDataSetter}
                  webinarData={webinarData}
                  setWebinarData={setWebinarData}
                />
              </Suspense>
            </Route>
            <Route path="/about">
              <Suspense fallback={<div>loading...</div>}>
                <About />
              </Suspense>
            </Route> */}

              {/* <Route path="/">
              <div className="width100vw">
                <div className="timeFrameSelector">
                  <div
                    className={
                      (selectedTimeScale === 2 && "timeFrameItemActive") ||
                      "timeFrameItem"
                    }
                    onClick={() => {
                      setTime("day", 2);
                    }}
                  >
                    On Now
                  </div>{" "}
                  <div
                    className={
                      (selectedTimeScale === 4 && "timeFrameItemActive") ||
                      "timeFrameItem"
                    }
                    onClick={() => {
                      setTime("month", 4);
                    }}
                  >
                    Popular
                  </div>
                </div>
                <Suspense fallback={<div>loading...</div>}>
                  <Main
                    chartID={`${window.location.pathname.split("/")[2]}`}
                    timeFrame={timeFrame}
                    chartData={chartData}
                    setChartData={chartDataSetter}
                  />
                </Suspense>
              </div>
            </Route> */}
              {/* </BrowserRouter>*/}
              {/* <div className="footer2">
          <a href={`/about/`}>About</a>
        </div> */}
              {/* </div> */}
            </div>
          </ThemeProvider>
        </div>
      </GoogleOAuthProvider>
    )) || (
      <div>
        {/* <Suspense fallback={<div>Loading...</div>}> */}
        <InlinePlayer2 />
        {/* </Suspense> */}
      </div>
    )
  );
}

export default App;
