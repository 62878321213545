import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    userData: {},
    user: {},
    accountPath: "",
    openAgreeToTerms: false,
    firebaseToken: "",
    isLoggedIn: false,
    inAccountPage: false,
  },
  reducers: {
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setAccountPath: (state, action) => {
      state.accountPath = action.payload;
    },
    setAgreeToTerms: (state, action) => {
      state.openAgreeToTerms = action.payload;
    },
    setFirebaseToken: (state, action) => {
      state.firebaseToken = action.payload;
    },
    setIsLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    setInAccountPage: (state, action) => {
      state.inAccountPage = action.payload;
    },
  },
});

export const {
  setUserData,
  setUser,
  setAccountPath,
  setAgreeToTerms,
  setFirebaseToken,
  setIsLoggedIn,
  setInAccountPage,
} = userSlice.actions;

export default userSlice.reducer;