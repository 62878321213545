import React, { useEffect, useState, lazy, Suspense } from "react";

import "./App.css";
import { ZoomMtg as ZoomMtg } from "@zoomus/websdk";

function App(props) {
  var params = new URLSearchParams(window.location.search);
  console.log("playerProps", params.get("url"), params.get("joinUrl"));

  var apiKey = process.env.REACT_APP_ZOOM_JWT_API_KEY;

  var signatureEndpoint =
    "https://us-central1-webinarstvus.cloudfunctions.net/signatureGetter2";

  var url = params.get("url");
  var role = 0;
  var observer;

  useEffect(() => {
    window.addEventListener("wheel", handleScroll);
  }, []);
  useEffect(() => {
    getSignature();
  }, [params.get("url")]);

  function handleScroll() {
    if (window.pageYOffset === 0) {
      // alert("I AM AT THE TOP");
    } else {
      // alert("bott");
    }
  }

  useEffect(() => {
    // ZoomMtg.setZoomJSLib();

    var zRoot = document.getElementById("zmmtg-root");
    if (zRoot) {
      zRoot.style.display = "block";
    }

    // getSignature();

    const divElement = document.getElementsByClassName("login-btn-zoom")[0];
    if (divElement) {
      divElement.click();
    } else {
      observer = new MutationObserver(() => {
        // console.log("clickjoin");
        const divElement = document.getElementsByClassName("login-btn-zoom")[0];
        if (divElement) {
          if (observer) {
            observer.disconnect();
            observer = null;
          }
          divElement.click();
        }
      });
      observer.observe(document, { subtree: true, childList: true });
    }

    return () => {
      if (observer) {
        observer.disconnect();
        observer = null;
      }
    };
  }, []);

  function getSignature(e) {
    ZoomMtg.setZoomJSLib("https://source.zoom.us/2.9.7/lib", "/av");
    ZoomMtg.preLoadWasm();
    ZoomMtg.prepareWebSDK();
    ZoomMtg.i18n.load("en-US");
    ZoomMtg.i18n.reload("en-US");
    console.log("url:", url);

    var headers = {
      "Content-Type": "text/plain",
    };
    var data = {
      url:
        params.get("joinUrl") &&
        params.get("joinUrl").split("/")[4].split("?")[0],
      role: role,
      sessionPasscode: "",
      userName: "WebinarTV.us Viewer",
    };

    console.log("sigData", data);

    if (params.get("url")) {
      fetch(signatureEndpoint, {
        method: "POST",
        body: JSON.stringify(data),
        headers: headers,
      })
        .then((res) => {
          return res.text();
        })
        .then((response) => {
          console.log("response", response);
          startMeeting(JSON.parse(response).signature);
        })
        .catch((error) => {
          console.log("error", error);
        });
    } else {
      console.log("nourl");
    }
  }

  function startMeeting(signature) {
    console.log("signature", signature);
    let joinUrl = window.location.href.split("joinUrl=")[1].split("\\")[0];
    ZoomMtg.leaveMeeting({});

    ZoomMtg.init({
      leaveUrl: window.location.href + "#" + window.location.hash + "1",
      disablePreview: true,

      success: (success) => {
        ZoomMtg.join({
          signature: signature,
          sdkKey: apiKey,
          meetingNumber: joinUrl.split("/")[4].split("?")[0],
          userName: "WebinarTV.us Viewer",
          userEmail: "webinarTV.us@gmail.com",
          passWord:
            (joinUrl &&
              joinUrl.split("pwd=")[1] &&
              joinUrl.split("pwd=")[1].split("&")[0]) ||
            undefined,
          tk:
            (joinUrl &&
              joinUrl.split("tk=")[1] &&
              joinUrl.split("tk=")[1].split("&")[0]) ||
            "",
          success: (success) => {
            let itt = 0;
            function doStuff() {
              let startButton = document.getElementsByClassName(
                "join-audio-by-voip__join-btn"
              )[0];
              if (startButton) {
                startButton.click();
                itt = 0;
              }
              if (itt > 20) {
                return;
              }
              itt++;

              setTimeout(doStuff, 500);
            }

            doStuff();
            ZoomMtg.showJoinAudioFunction({
              show: false,
            });

            console.log(success);
          },
          error: (error) => {
            console.log(error);
          },
        });
      },
      error: (error) => {
        console.log(error);
      },
    });

    ZoomMtg.getCurrentUser({
      success(response) {
        console.log("getCurrentUser success");
        ZoomMtg.mute({
          userId: response.result.currentUser.userId,
          mute: true,
        });
      },
    });
  }

  return (
    <div className="App2">
      <main>
        <div className="zmmtg-root"></div>
      </main>
    </div>
  );
}

export default App;
